import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ProfileHeader from "./components/profile-header"
import MainMenu from "./components/main-menu"
import LanguageMenu from "./components/language"
import detectPassiveEvents from "../../tool/detectPassiveEvents"
import { getMainMenuDestinations, toggleMenu } from "../../../redux/actions/MenuActions"
import { fetchExperienceTourTags } from "../../../redux/actions/HomeActions"
import { configPopup, _isEqual } from "../../tool/util"
import "./index.less"

let currentScrollingPosition = 0
let shouldUpdateScrolling = false
class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            onTouchStartX: 0,
            onTouchMoving: false,
        }
    }

    componentDidMount() {
        if (document) {
            const obj = document.getElementById("APP")
            if (detectPassiveEvents.hasSupport) {
                obj.addEventListener(
                    "touchstart",
                    this.handleTouchStart,
                    { capture: false, passive: true },
                )
                obj.addEventListener(
                    "touchmove",
                    this.handleTouchMove,
                    { capture: false, passive: true },
                )
                obj.addEventListener(
                    "touchend",
                    this.handleTouchEnd,
                    { capture: false, passive: true },
                )
            } else {
                obj.addEventListener("touchstart", this.handleTouchStart, false)
                obj.addEventListener("touchmove", this.handleTouchMove, false)
                obj.addEventListener("touchend", this.handleTouchEnd, false)
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        // Allow to update profile data in menu
        if (!_isEqual(this.props.getProfileData, nextProps.getProfileData)) {
            return true
        }
        if (!_isEqual(this.props.configurations, nextProps.configurations)) {
            return true
        }
        // @autor tony, tonys9204@gmail.com
        // start
        // to make sure can get correct current scrollTop potion
        // ps: based on palak's code already exsit before
        if (this.props.isMenuHidden && !this.state.onTouchMoving) {
            currentScrollingPosition = document.scrollingElement.scrollTop
        }
        shouldUpdateScrolling = this.props.isMenuHidden !== nextProps.isMenuHidden
        return shouldUpdateScrolling
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isMenuHidden
            && !this.props.isMenuHidden
        ) {
            if (!this.props.destinationsData.ok) {
                this.props.getMainMenuDestinations()
            }
            if (
                !this.props.experienceData
                || this.props.experienceData.length === 0
            ) {
                this.props.fetchExperienceTourTags()
            }
        }
    }

    componentWillUnmount() {
        if (document) {
            const obj = document.getElementById("APP")
            obj.removeEventListener("touchstart", this.handleTouchStart)
            obj.removeEventListener("touchmove", this.handleTouchMove)
            obj.removeEventListener("touchend", this.handleTouchEnd)
        }
        this.hideMenu(true)
    }

    showToogle = () => {
        this.props.toggleMenu(true)
    }

    hideMenu = val => {
        this.props.toggleMenu(val)
    }

    handleTouchStart = e => {
        if (this.props.disableMenu) return
        if (this.state.onTouchMoving === false) {
            this.setState({
                onTouchStartX: e.changedTouches[0].pageX,
            })
        }
    }

    handleTouchMove = () => {
        if (this.props.disableMenu) return
        if (!this.state.onTouchMoving) {
            this.setState({ onTouchMoving: true })
        }
    }

    handleTouchEnd = e => {
        if (this.props.disableMenu) return
        if ((this.state.onTouchStartX < 20)
            && ((this.state.onTouchStartX + 100) < e.changedTouches[0].pageX)) {
            this.hideMenu(false)
        } else if (
            (e.changedTouches[0].pageX + 60) < this.state.onTouchStartX
            && !this.props.isMenuHidden
        ) {
            this.hideMenu(true)
        }
        this.setState({ onTouchMoving: false })
    }

    render() {
        // @autor tony, tonys9204@gmail.com
        // start
        // to set no scrolling on body while side-menu open and close
        if (typeof window !== "undefined" && shouldUpdateScrolling) {
            configPopup(!this.props.isMenuHidden, currentScrollingPosition)
        }
        // end
        return (
            <div
                className={cx("nav_wrapper", {
                    nav_wrapper_open: !this.props.isMenuHidden,
                })}
            >
                <div className="nav_close_sign" onClick={this.showToogle}>
                    {""}
                </div>
                <nav className="nav_container">
                    <ProfileHeader
                        isGuest={
                            this.props.getProfileData.type === "guest"
                        }
                        ProfileImg={this.props.getProfileData.avatar}
                        displayName={this.props.getProfileData.display_name}
                        points={this.props.getProfileData.points}
                        history={this.props.history}
                    />
                    <MainMenu
                        isGuest={this.props.canActivateGuestAccountData.can_activate}
                        history={this.props.history}
                    />
                </nav>
                <LanguageMenu
                    history={this.props.history}
                />
            </div>
        )
    }
}
Menu.propTypes = {
    destinationsData: PropTypes.oneOfType([PropTypes.object]),
    getMainMenuDestinations: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    isMenuHidden: PropTypes.bool,
    canActivateGuestAccountData: PropTypes.oneOfType([PropTypes.object]),
    getProfileData: PropTypes.oneOfType([PropTypes.object]),
}
Menu.defaultProps = {
    isMenuHidden: true,
    disableMenu: false,
    canActivateGuestAccountData: { can_activate: false },
    getProfileData: {
        avatar: "",
        cart_size: 0,
        country: { ip: { iso_2: "" } },
        display_name: "",
        email: "",
        first_name: "",
        last_name: "",
        points: 0,
        src: "",
    },
    destinationsData: {
        ok: false,
        message: "",
        execute_time: "",
        data: {
            href: "",
            id: "",
            name: "",
            links: [{
                href: "",
                id: "",
                name: "",
                links: [{
                    href: "",
                    id: "",
                    name: "",
                    image: "",
                    links: [{
                        href: "",
                        name: "",
                    }],
                }],
            }],
        },
    },
}

const mapStateToProps = state => ({
    ...state.LoginReducer,
    ...state.MenuReducers,
    experienceData: state.HomeReducer.experienceData,
})
export default connect(mapStateToProps, {
    getMainMenuDestinations,
    toggleMenu,
    fetchExperienceTourTags,
})(Menu)
