import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"
import { connect } from "react-redux"
import MenuAct from "../../../../redux/actions/MenuActions"
import StylesSprite from "../../../../assets/less/sprite.less"

class ShowDestinationsMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isDestinationsMenu: true,
        }
        this.subMenuItem = []
    }

    UNSAFE_componentWillReceiveProps(next) {
        if (
            next.isMenuHidden
            && next.showMainMenuValue
            && !next.showDestinationsValue
        ) {
            this.setState({
                isDestinationsMenu: true,
            })
        }
    }

    drawLocation = () => (
        this.props.destinationData.map(item => (
            <li
                key={item.id}
                ref={nod => {
                    this.subMenuItem[item.id] = nod
                }}
                onClick={() => {
                    Object.keys(this.subMenuItem).map(key => {
                        if (key !== item.id) {
                            this.subMenuItem[key].classList.remove("active")
                        }
                    })
                    this.subMenuItem[item.id].classList.toggle("active")
                    // scrolling start
                    setTimeout(() => {
                        const menuElements = document.getElementsByClassName("nav_container")[0]
                        const innerBlueMenu = document.getElementsByClassName("submain_div blue_menu")[0]
                        if (
                            innerBlueMenu !== null && innerBlueMenu
                            && menuElements !== null && menuElements
                            && this.subMenuItem[item.id] !== null && this.subMenuItem[item.id]
                        ) {
                            menuElements.scrollTop = (
                                innerBlueMenu.offsetTop + this.subMenuItem[item.id].offsetTop
                            ) - (window.screen.height / 2)
                        }
                    }, 100)
                    // debounce(() => {
                    //     menuElements.scrollTop = (
                    //         innerBlueMenu.offsetTop + this.subMenuItem[item.id].offsetTop
                    //     ) - (window.screen.height / 2)
                    // }, 100)()
                    // scrolling formula = Clicked element offsetTop - device half height
                    // scrolling end
                }}
            >
                <div className="submain_div">
                    <em className={`${StylesSprite.sprite_base} sprite_menu_pin`} />
                    <div className="wrap_text_menu">
                        {item.name}
                    </div>
                    <span className="sub_menu_sign_inner">
                        <em className={`${StylesSprite.sprite_base} sprite_submenu_uparow`} />
                    </span>
                </div>
                <ul>
                    {item.links.map((subItem, index) => (
                        <div
                            onClick={() => {
                                this.setState({ isDestinationsMenu: true })
                                this.props.showDestinations(false)
                                this.props.toggleMenu(true)
                            }}
                            key={index}
                            className="main_link"
                        >
                            {
                                this.props.history.location.pathname !== subItem.href
                                && (
                                    <Link to={subItem.href} className="bold_menu_item">
                                        <li>
                                            {subItem.name}
                                        </li>
                                    </Link>
                                )
                            }
                            {subItem.links.map((city, cityKey) => {
                                if (typeof city.links !== "undefined") {
                                    return (
                                        <li key={cityKey}>
                                            {city.links.map((cityItem, cityItemKey) => (
                                                <Link className="multipleLink" to={cityItem.href} key={cityKey + "_" + cityItemKey}>
                                                    {cityItem.name}
                                                </Link>
                                            ))}
                                        </li>
                                    )
                                }
                                return (
                                    <Link to={city.href} key={cityKey}>
                                        <li>{city.name}</li>
                                    </Link>
                                )
                            })}
                        </div>
                    ))}
                </ul>
            </li>
        )))

    render() {
        return (
            <div className="bt_main_menu">
                <div
                    className={cx(
                        "submain_div",
                        { "dis-none": !this.state.isDestinationsMenu },
                    )}
                >
                    <ul>
                        {this.drawLocation()}
                    </ul>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let destinationData = []
    if (
        state.InitData.configurations
        && state.InitData.configurations.home_navigation_links
        && state.InitData.configurations.home_navigation_links.links.length > 0
    ) {
        state.InitData.configurations.home_navigation_links.links.map(item => {
            if (item.id === "destination") {
                destinationData = item.links
            }
        })
    }
    return {
        ...state.MenuReducers,
        destinationData,
    }
}
export default connect(mapStateToProps, { ...MenuAct })(ShowDestinationsMenu)
