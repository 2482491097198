import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import MenuAct from "../../../../redux/actions/MenuActions"
import StylesSprite from "../../../../assets/less/sprite.less"

const ExperienceMenu = props => (
    <div className="bt_main_menu">
        <div className="submain_div">
            <ul className="experience_menu">
                {
                    props.experienceData
                    && props.experienceData.length > 0
                    && props.experienceData.map((item, key) => (
                        <Link
                            to={item.href}
                            key={key}
                            onClick={() => {
                                props.toggleMenu(true)
                                props.showExperienceMenu(false)
                            }}
                        >
                            <li>
                                <div className="submain_div">
                                    <div className="experience_icon">
                                        <em
                                            className={`${StylesSprite.sprite_base} sprite_menu_${
                                                item.mimage.split(".")[0]
                                            }`}
                                        />
                                    </div>
                                    {item.name}
                                </div>
                            </li>
                        </Link>
                    ))
                }
            </ul>
        </div>
    </div>
)
function mapStateToProps(state) {
    let experienceData = []
    if (
        state.InitData.configurations
        && state.InitData.configurations.home_navigation_links
        && state.InitData.configurations.home_navigation_links.links.length > 0
    ) {
        state.InitData.configurations.home_navigation_links.links.map(item => {
            if (item.id === "experience") {
                experienceData = item.links
            }
        })
    }
    return {
        ...state.MenuReducers,
        experienceData,
    }
}
export default connect(mapStateToProps, { ...MenuAct })(ExperienceMenu)
