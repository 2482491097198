import React from "react"
// import { Link } from "react-router-dom"
import cx from "classnames"
import { connect } from "react-redux"
import configs, { t } from "../../../../../config"
import MenuAct from "../../../../redux/actions/MenuActions"
import { setCurrencyCookie, setCurrency } from "../../../../redux/actions/CommonActions"
import { _isEqual } from "../../../tool/util"
import StylesSprite from "../../../../assets/less/sprite.less"

class LanguageMenu extends React.Component {
    constructor(props) {
        super(props)
        let selectedCurrency = {
            sign: "$ USD",
            code: "USD",
            symbol: "$",
        }
        if (props.selectedCurrency) {
            selectedCurrency = props.selectedCurrency
        }

        this.state = {
            selectedLang: configs.language.name,
            selectedCurrency,
        }
        this.defaultLang = configs.language.name
    }
    changeLang = lang => {
        this.setState({ selectedLang: lang })
    }
    changeCurrency = currency => {
        this.setState({ selectedCurrency: currency })
    }
    showLanguagePopup = val => {
        this.setState({ selectedCurrency: this.props.selectedCurrency })
        this.setState({ selectedLang: configs.language.name })
        this.props.showLanguageMenu(val)
    }
    handleSubmit = () => {
        if (typeof window !== "undefined") {
            // Set currency
            if (
                !_isEqual(this.props.selectedCurrency, this.state.selectedCurrency)
            ) {
                // for mobile application or local env
                if (
                    typeof document !== "undefined"
                    && location.hostname === "localhost"
                ) {
                    const d = new Date()
                    d.setTime(d.getTime() + (365 * 24 * 60 * 60))
                    const expires = "expires=" + d.toUTCString()
                    document.cookie =
                    `currency=${this.state.selectedCurrency.code}; expires=${expires}; path=/;`
                }
                this.props.setCurrencyCookie(this.state.selectedCurrency.code, () => {
                    // this.props.setCurrency(this.state.selectedCurrency)
                    // remove session storage & algoliasearch data for fresh result
                    localStorage.removeItem("algoliasearch-client-js")
                    sessionStorage.clear()
                    // Reload App
                    window.location.reload()
                })
                this.props.toggleMenu(true)
            }

            // Set Language
            if (
                this.defaultLang !== "es_ES"
                && this.state.selectedLang === "es_ES"
            ) {
                window.location = configs.url.mt4fES
            } else if (
                this.defaultLang !== "en_US"
                && this.state.selectedLang === "en_US"
            ) {
                window.location = configs.url.mt4fUS
            } else if (
                this.state.selectedLang === "ch_CH"
            ) {
                window.location = configs.url.mtff
            } else if (
                this.defaultLang === "es_ES"
                && this.state.selectedLang === "es_ES"
            ) {
                this.showLanguagePopup(false)
            } else if (
                this.defaultLang === "en_US"
                && this.state.selectedLang === "en_US"
            ) {
                this.showLanguagePopup(false)
            }
        } else {
            console.error("window undefined")
        }
    }
    render() {
        return (
            <div>
                <div className={cx(
                    "popup_main_overlay",
                    { "dis-none": !this.props.showLanguageMenuValue },
                )}
                >
                    <div
                        className="popup_support_overlay"
                        onClick={
                            () => this.showLanguagePopup(false)}
                    > {""}
                    </div>
                    <div
                        className={cx(
                            "popup_white_box",
                            { "dis-none": !this.props.showLanguageMenuValue },
                        )}
                    >
                        <div
                            className="grey_close_sign"
                            onClick={() => this.showLanguagePopup(false)}
                            style={{
                                textAlign: "right",
                                marginRight: "0.15rem",
                            }}
                        >
                            <em
                                className={`${StylesSprite.sprite_base} close-icon-grey`}
                                style={{
                                    position: "fixed",
                                    right: "auto",
                                    top: "auto",
                                    margin: "-3px 0 0",
                                }}
                            />
                        </div>
                        <h2>{t("Options")}</h2>
                        <div className="sub_option_radio_div">
                            <h3>{t("Language")}</h3>
                            <div
                                className={cx(
                                    "option_row",
                                    { active: this.state.selectedLang === "en_US" },
                                )}
                                onClick={() => this.changeLang("en_US")}
                            >
                                <div className="radio_btn_div"> {""} </div>
                                <div className="radio_btn_label">{t("English")}</div>
                                <div className="clear_div"> {""} </div>
                            </div>
                            <div
                                className={cx(
                                    "option_row",
                                    { active: this.state.selectedLang === "es_ES" },
                                )}
                                onClick={() => this.changeLang("es_ES")}
                            >
                                <div className="radio_btn_div"> {""} </div>
                                <div className="radio_btn_label">{t("Spanish")}</div>
                                <div className="clear_div"> {""} </div>
                            </div>
                            <div
                                className={cx(
                                    "option_row",
                                    { active: this.state.selectedLang === "ch_CH" },
                                )}
                                onClick={() => this.changeLang("ch_CH")}
                            >
                                <div className="radio_btn_div"> {""} </div>
                                <div className="radio_btn_label">{t("Chinese")}</div>
                            </div>
                            <h3>{t("Currency")}</h3>
                            {this.props.configurations
                                && this.props.configurations.supported_currencies
                                && this.props.configurations.supported_currencies
                                    .map((item, index) =>
                                        (<div
                                            key={index}
                                            className={cx(
                                                "option_row",
                                                {
                                                    active: this.state
                                                        .selectedCurrency.code === item.code,
                                                },
                                            )}
                                            onClick={() => this.changeCurrency(item)}
                                        >
                                            <div className="radio_btn_div"> {""} </div>
                                            <div className="radio_btn_label">
                                                {item.sign}
                                            </div>
                                            <div className="clear_div"> {""} </div>
                                        </div>))}
                        </div>
                        <div className="popup_btn">
                            <button
                                className="cancel_btn"
                                onClick={
                                    () => this.showLanguagePopup(false)}
                            >{t("Cancel")}
                            </button>
                            <input
                                type="submit"
                                value={t("Done")}
                                className="bt_done_btn"
                                onClick={
                                    () => { this.handleSubmit() }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.InitData,
        ...state.CommonReducers,
        ...state.MenuReducers,
    }
}
export default connect(
    mapStateToProps,
    { ...MenuAct, setCurrencyCookie, setCurrency },
)(LanguageMenu)
