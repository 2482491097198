import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faSignOutAlt } from "@fortawesome/free-solid-svg-icons"
import MenuAct from "../../../../redux/actions/MenuActions"
import { authenticate, updateRequestedPath, LogoutUser } from "../../../../redux/actions/LoginActions"
import { t, isT4Fes, blogLink } from "../../../../../config"
import StylesSprite from "../../../../assets/less/sprite.less"
import ShowDestinationsMenu from "./show-destinations"
import ExperienceMenu from "./experience"
import { offlineRetrive } from "../../../tool/util"

class MainMenu extends React.Component {
    constructor(props) {
        super(props)
        this.props.authenticate()
    }

    setYourBooking= () => {
        if (this.props.authenticated) {
            return (
                <Link to="/account_history_info.html">
                    <li onClick={this.hideMenu}>
                        <div className="main_menu_icon">
                            <em className={`${StylesSprite.sprite_base} sprite_menu_booking`} />
                        </div>
                        <div className="menu_name">{t("Your Bookings")}</div>
                    </li>
                </Link>
            )
        }
        return (
            <Link to="/login">
                <li onClick={() => {
                    this.hideMenu()
                    this.props.updateRequestedPath("reservation-search")
                }}
                >
                    <div className="main_menu_icon">
                        <em className={`${StylesSprite.sprite_base} sprite_menu_booking`} />
                    </div>
                    <div className="menu_name">{t("Your Bookings")}</div>
                </li>
            </Link>
        )
    }

    setLogout= () => {
        if (this.props.authenticated) {
            return (
                <li onClick={() => {
                    this.hideMenu()
                    this.props.LogoutUser()
                }}
                >
                    <div className="main_menu_icon">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                    </div>
                    <div className="menu_name">{t("Logout")}</div>
                </li>
            )
        }
        return null
    }

    handleSubmain = () => {
        this.props.toggleMenu(false)
        this.props.showLanguageAndCurrency(true)
    }

    hideMenu= () => {
        this.props.toggleMenu(true)
    }

    render() {
        let wishlistItem = offlineRetrive("toursWishlist")
        wishlistItem = wishlistItem !== null ? wishlistItem.length : 0
        return (
            <div
                className={cx({ "dis-none": !this.props.showMainMenuValue })}
            >
                <div className="bt_main_menu">
                    <div className="submain_div">
                        <ul>
                            {this.props.history.location.pathname !== "/"
                                ? (
                                    <Link to="/">
                                        <li onClick={this.hideMenu}>
                                            <div className="main_menu_icon">
                                                <em className={`${StylesSprite.sprite_base} sprite_menu_home`} />
                                            </div>
                                            <div className="menu_name">{t("Home")}</div>
                                        </li>
                                    </Link>
                                )
                                : (
                                    <li onClick={this.hideMenu}>
                                        <div className="main_menu_icon">
                                            <em className={`${StylesSprite.sprite_base} sprite_menu_home`} />
                                        </div>
                                        <div className="menu_name">{t("Home")}</div>
                                    </li>
                                )}
                            {this.setYourBooking()}
                            {this.props.history.location.pathname !== "/wishlist"
                                ? (
                                    <Link to="/wishlist">
                                        <li onClick={this.hideMenu}>
                                            <div className="main_menu_icon">
                                                <em className={`${StylesSprite.sprite_base} sprite_menu_wishlist`} />
                                                {
                                                    wishlistItem > 0
                                                && <span className="favourite-item-count">{wishlistItem}</span>
                                                }
                                            </div>
                                            <div className="menu_name">{t("Your Wish List")}</div>
                                        </li>
                                    </Link>
                                )
                                : (
                                    <li onClick={this.hideMenu}>
                                        <div className="main_menu_icon">
                                            <em className={`${StylesSprite.sprite_base} sprite_menu_wishlist`} />
                                            {
                                                wishlistItem > 0
                                            && <span className="favourite-item-count">{wishlistItem}</span>
                                            }
                                        </div>
                                        <div className="menu_name">{t("Your Wish List")}</div>
                                    </li>
                                )}
                            {this.props.history.location.pathname !== "/recently-viewed"
                                ? (
                                    <Link to="/recently-viewed">
                                        <li onClick={this.hideMenu}>
                                            <div className="main_menu_icon">
                                                <em className={`${StylesSprite.sprite_base} sprite_menu_recently_view`} />
                                            </div>
                                            <div className="menu_name">{t("Recently Viewed")}</div>
                                        </li>
                                    </Link>
                                )
                                : (
                                    <li onClick={this.hideMenu}>
                                        <div className="main_menu_icon">
                                            <em className={`${StylesSprite.sprite_base} sprite_menu_recently_view`} />
                                        </div>
                                        <div className="menu_name">{t("Recently Viewed")}</div>
                                    </li>
                                )}
                        </ul>
                    </div>
                    <div className="submain_div blue_menu">
                        <ul>
                            <li>
                                <div
                                    onClick={
                                        () => {
                                            this
                                                .props
                                                .showDestinations(!this.props.showDestinationsValue)
                                        }
                                    }
                                >
                                    <div className="main_menu_icon">
                                        <em className={`${StylesSprite.sprite_base} sprite_menu_search`} />
                                    </div>
                                    <div
                                        className={
                                            cx("menu_name", {
                                                active: this.props.showDestinationsValue,
                                            })
                                        }
                                    >
                                        {t("Destinations")}
                                        <span className="sub_menu_sign">
                                            <em
                                                className={`${StylesSprite.sprite_base} sprite_right_icon`}
                                            />
                                        </span>
                                    </div>
                                </div>
                                {
                                    this.props.showDestinationsValue
                                    && (
                                        <ShowDestinationsMenu
                                            history={this.props.history}
                                        />
                                    )
                                }
                            </li>
                            {!isT4Fes && (
                                <li>
                                    <div
                                        onClick={
                                            () => {
                                                this
                                                    .props
                                                    .showExperienceMenu(!this.props.showExperience)
                                            }
                                        }
                                    >
                                        <div className="main_menu_icon">
                                            <em className={`${StylesSprite.sprite_base} sprite_menu_trip`} />
                                        </div>
                                        <div
                                            className={
                                                cx("menu_name", {
                                                    active: this.props.showExperience,
                                                })
                                            }
                                        >
                                            {t("Experience")}
                                            <span className="sub_menu_sign">
                                                <em
                                                    className={`${StylesSprite.sprite_base} sprite_right_icon`}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        this.props.showExperience
                                        && (
                                            <ExperienceMenu
                                                history={this.props.history}
                                            />
                                        )
                                    }
                                </li>
                            )}
                            {!isT4Fes && (
                                <a href={`/landing/custom-travel.html/?ifm=1&currency=${this.props.currency}`}>
                                    <li>
                                        <div className="main_menu_icon">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </div>
                                        <div className="menu_name">{t("Custom Travel")}</div>
                                    </li>
                                </a>
                            )}
                            <li
                                onClick={() => window.open(blogLink)}
                            >
                                <div className="main_menu_icon">
                                    <i className={`${StylesSprite.sprite_base} travel_ideas`} aria-hidden="true" />
                                </div>
                                <div className="menu_name">{t("Travel Ideas")}</div>
                            </li>
                            {/*
                            <Link
                                to={{
                                    pathname: t("DESKTOP_SITE_ROUTE_HOTELS"),
                                    state: "needToReload"
                                }}
                            >
                            <li>
                                <div className="main_menu_icon">
                                    <i className="fa fa-bed enable_opacity" aria-hidden="true" />
                                </div>
                                <div className="menu_name">{t("Hotels")}</div>
                            </li>
                            </Link> */}
                            {/* <a href={`${configs.url.t4fUrl}/cruises`}>
                                <li>
                                    <i className="fa fa-ship enable_opacity" aria-hidden="true" />
                                    <div className="menu_name">{t("Cruises")}</div>
                                </li>
                            </a> */}
                            {/* <Link to="/">
                                <li onClick={this.hideMenu}>
                                    <i className="fa fa-user-o" aria-hidden="true" />
                                    <div className="menu_name">{t("Local Experts")}</div>
                                </li>
                            </Link> */}
                        </ul>
                    </div>
                    <div className="submain_div">
                        <ul>
                            <a href={`/special-travel-offers-and-deals/?ifm=1&currency=${this.props.currency}`}>
                                <li>
                                    <div className="main_menu_icon">
                                        <em className={`${StylesSprite.sprite_base} sprite_menu_deal`} />
                                    </div>
                                    <div className="menu_name">{t("Today’s Deals")}</div>
                                </li>
                            </a>
                            {this.props.history.location.pathname !== "/tours/near-me"
                                ? (
                                    <Link to="/tours/near-me">
                                        <li onClick={this.hideMenu}>
                                            <div className="main_menu_icon">
                                                <em className={`${StylesSprite.sprite_base} sprite_menu_nearby`} />
                                            </div>
                                            <div className="menu_name">{t("Nearby")}</div>
                                        </li>
                                    </Link>
                                )
                                : (
                                    <li onClick={this.hideMenu}>
                                        <div className="main_menu_icon">
                                            <em className={`${StylesSprite.sprite_base} sprite_menu_nearby`} />
                                        </div>
                                        <div className="menu_name">{t("Nearby")}</div>
                                    </li>
                                )}
                            {this.props.history.location.pathname !== "/help"
                                ? (
                                    <Link to="/help">
                                        <li onClick={this.hideMenu}>
                                            <div className="main_menu_icon">
                                                <em className={`${StylesSprite.sprite_base} sprite_menu_contact_support`} />
                                            </div>
                                            <div className="menu_name">{t("Contact Support")}</div>
                                        </li>
                                    </Link>
                                )
                                : (
                                    <li onClick={this.hideMenu}>
                                        <div className="main_menu_icon">
                                            <em className={`${StylesSprite.sprite_base} sprite_menu_contact_support`} />
                                        </div>
                                        <div className="menu_name">{t("Contact Support")}</div>
                                    </li>
                                )}
                            <li>
                                <div className="main_menu_icon">
                                    <em className={`${StylesSprite.sprite_base} sprite_menu_language`} />
                                </div>
                                <div
                                    onClick={() => {
                                        this.props.showLanguageMenu(true)
                                    }}
                                    className="menu_name"
                                >
                                    {t("Language & Currency")}
                                </div>
                            </li>
                            {this.setLogout()}
                        </ul>
                    </div>

                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.LoginReducer,
        ...state.MenuReducers,
        currency: state.CommonReducers.selectedCurrency.code,
    }
}
export default connect(
    mapStateToProps,
    {
        ...MenuAct,
        authenticate,
        updateRequestedPath,
        LogoutUser,
    },
)(MainMenu)
