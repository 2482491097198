import Constant from "../constants"
import { get } from "../../common/api"

export const toggleMenu = data => dispatch => {
    dispatch({
        type: Constant.SHOW_MENU_TOGGLE,
        payload: data,
    })
    if (typeof (window) !== "undefined") {
        document.body.classList.toggle("noscroll", !data)
        document.body.classList.toggle("prevent_scroll", !data)
    }
}

export const showMenu = data => dispatch => {
    dispatch({
        type: Constant.SHOW_MENU,
        payload: data,
    })
}

export const showDestinations = data => dispatch => {
    dispatch({
        type: Constant.SHOW_MENU_DESTINATIONS,
        payload: data,
    })
}

export const showLanguageMenu = data => dispatch => {
    dispatch({
        type: Constant.SHOW_LANGUAGE_MENU,
        payload: data,
    })
}

export const showExperienceMenu = data => dispatch => {
    dispatch({
        type: Constant.SHOW_EXPERIENCE_MENU,
        payload: data,
    })
}

export const getMainMenuDestinations = () => dispatch => {
    dispatch({
        type: Constant.MENU_DESTINATIONS_DATA_REQUEST,
    })
    get("/common/getMainMenuDestinations")
        .then(data =>
            dispatch({
                type: Constant.MENU_DESTINATIONS_DATA_SUCCESS,
                payload: data,
            }))
        .catch(err => {
            console.error(err)
        })
}
export default {
    toggleMenu,
    showMenu,
    showDestinations,
    showExperienceMenu,
    getMainMenuDestinations,
    showLanguageMenu,
}
