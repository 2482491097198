import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MenuAct from "../../../../redux/actions/MenuActions"
import { getAuthToken } from "../../../../common/tool/auth"
import { t } from "../../../../../config"
import StylesSprite from "../../../../assets/less/sprite.less"

class ProfileHeader extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (this.props.isGuest === nextProps.isGuest &&
          this.props.ProfileImg === nextProps.ProfileImg &&
          this.props.displayName === nextProps.displayName &&
          this.props.points === nextProps.points) {
            return false
        }
        return true
    }
    getName = () => {
        const { userDetail } = this.props.accountInfo
        if (this.props.accountInfo.isFetched) {
            return `${userDetail.firstname} ${userDetail.lastname}`
        }
        return this.props.displayName
    }
    hideMenu= () => {
        this.props.toggleMenu(true)
    }
    showProfile= () => {
        if (this.props.displayName) {
            if (this.props.isGuest) {
                return (
                    <Link to="/login">
                        <h2>{t("LOGIN / SIGN UP")}</h2>
                    </Link>
                )
            }
            if (this.props.displayName === "LOGIN") {
                return (
                    <Link to="/login">
                        <h2>{t("LOGIN")}</h2>
                    </Link>
                )
            }
            const token = getAuthToken()
            if (token) {
                return (
                    <Link to="/account.html">
                        <h2>{this.getName()}</h2>
                    </Link>
                )
            }
            return (
                <Link to="/profile">
                    <h2>{this.props.displayName}</h2>
                </Link>
            )
        }
        return (
            <Link to="/login">
                <h2>{t("Login")}</h2>
            </Link>
        )
    }
    showProfilePicture= () => {
        if (this.props.ProfileImg) {
            if (this.props.isGuest) {
                return (
                    <Link to="/login">
                        <img alt="Guest" src={this.props.ProfileImg} />
                    </Link>
                )
            }
            const token = getAuthToken()
            if (token) {
                return (
                    <Link to="/account.html">
                        <img alt={t("Profile")} src={this.props.ProfileImg} />
                    </Link>
                )
            }
            return (
                <Link to="/profile">
                    <img alt={t("Profile")} src={this.props.ProfileImg} />
                </Link>
            )
        }
        return (
            <Link to="/login">
                <img alt="login" src={this.props.ProfileImg} />
            </Link>
        )
    }
    render() {
        return (
            <div className="nav_profile_header">
                <div
                    className="nav_profile_pic"
                    onClick={this.hideMenu}
                >
                    {this.props.ProfileImg ?
                        this.showProfilePicture()
                        :
                        <Link to="/login">
                            <em className={`${StylesSprite.sprite_base} default_user_icon`} />
                        </Link>
                    }
                </div>
                <div className="nav_profile_text">
                    <div onClick={this.hideMenu}>
                        {this.showProfile()}
                    </div>
                    <div>
                        {(this.props.points > 0 && !this.props.isGuest) &&
                            <a>
                                <p>
                                    <span className="pointcounter">
                                        {this.props.points}
                                    </span>
                                    <span className="pointtext">{t("Reward Points")}</span>
                                </p>
                            </a>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
ProfileHeader.propTypes = {
    isGuest: PropTypes.bool,
    ProfileImg: PropTypes.string,
    displayName: PropTypes.string,
    toggleMenu: PropTypes.func.isRequired,
    points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
ProfileHeader.defaultProps = {
    isGuest: false,
    ProfileImg: "",
    displayName: "LOGIN",
    points: 0,
}
function mapStateToProps(state) {
    return {
        ...state.MenuReducers,
        accountInfo: { ...state.AccountReducers.accountInformation },
    }
}
export default connect(mapStateToProps, { ...MenuAct })(ProfileHeader)
